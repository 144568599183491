import  httpRequest  from './util/httpRequest';


$(document).on('click', '#date', function(event) {
  event.preventDefault();
  showModal(this)
})

function showModal(ths)
  {
    var date = ths.innerText;
    $("#exampleModal").modal('show');
    let target = $(ths).data('target')
    let formData = new FormData();
    formData.append('date', date)
    httpRequest.post('/getdata/', formData, {}, false)
    .done(function(res) { 
      $("#record_table tbody").html(res["data"]);
      $("#exampleModalLabel").html('Number of '+date);
    })
    .fail(function(error) {
      $(target).html('')
    })
  }


  function getStates(ths){
  let formData = new FormData();
  let target = $(ths).data('target')
  formData.append('country_id', $(ths).val())
  httpRequest.post('/countries/get-states', formData, {}, false)
    .done(function(res) {
      let options = `<option value =''>${localeMsg.select_state}</option>`
      $(res.data.states).each(function( index, state ) {
        options += `<option value = ${state.id}>${state.name}</option>`
      })
      $(target).html(options)
      if(res.data.country_phone_code != '')
        $('#user_country_code').val("+"+res.data.country_phone_code.toString())
        $('#project_country_code').val("+"+res.data.country_phone_code.toString())
      $('#user_city_id').html(`<option value =''></option>`)
      $('#contact_city').html(`<option value =''>${localeMsg.select_city}</option>`)
    })
    .fail(function(error) {
      console.log("Failed");
    })
}